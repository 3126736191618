<template>
  <div>
    <div v-if="calendarData" class="position-table">
      <md-table :value="calendarData">
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="День №" md-sort-by="id">
            <span>{{ index + 1 }}</span>
          </md-table-cell>
          <md-table-cell md-label="Дата" md-sort-by="id">
            <span>{{ item.date }}</span>
          </md-table-cell>
          <md-table-cell md-label="Награда предмет">
            <md-field>
              <md-select v-model="item.item">
                <md-option
                  v-for="reward in items"
                  :key="`option_item_${reward.id}`"
                  :value="reward.id"
                >
                  {{ reward.title }}
                </md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Награда количество">
            <md-field>
              <md-input v-model="item.numbers" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Особенный день">
            <md-switch v-model="item.isSpecDay" class="md-primary"></md-switch>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    calendarData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Предмет 1",
        },
        {
          id: 2,
          title: "Предмет 2",
        },
        {
          id: 3,
          title: "Предмет 3",
        },
        {
          id: 4,
          title: "Предмет 4",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
</style>
