import { render, staticRenderFns } from "./CalendarRewards.vue?vue&type=template&id=225f1828&scoped=true"
import script from "./CalendarRewards.vue?vue&type=script&lang=js"
export * from "./CalendarRewards.vue?vue&type=script&lang=js"
import style0 from "./CalendarRewards.vue?vue&type=style&index=0&id=225f1828&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225f1828",
  null
  
)

export default component.exports