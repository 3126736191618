<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <md-card-header data-background-color="blue" class="card-header">
              <div class="card-header-info">
                <h4 class="title">Настройка основной информации</h4>
              </div>
            </md-card-header>

            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item"
              mode="passive"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="title" />
              </md-field>
            </ValidationProvider>
            <div class="d-flex">
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item"
                mode="passive"
                tag="div"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>С</label>
                  <DatePicker
                    v-model="dateTill"
                    prefix-class="xmx"
                    type="date"
                    :show-second="false"
                    lang="ru"
                    :popup-style="{
                      left: 0,
                    }"
                    :append-to-body="false"
                    value-type="format"
                    format="DD.MM.YYYY"
                  >
                    <template #input>
                      <md-input v-model="dateTill" class="w-full" />
                    </template>
                  </DatePicker>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item"
                mode="passive"
                tag="div"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>По</label>
                  <DatePicker
                    v-model="dateFrom"
                    prefix-class="xmx"
                    type="date"
                    :show-second="false"
                    lang="ru"
                    :popup-style="{
                      left: 0,
                    }"
                    :append-to-body="false"
                    value-type="format"
                    format="DD.MM.YYYY"
                  >
                    <template #input>
                      <md-input v-model="dateFrom" class="w-full" />
                    </template>
                  </DatePicker>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              class="md-layout-item d-flex align-items-center"
              mode="passive"
              tag="div"
            >
              <label class="mr-10">Повторяемость</label>
              <md-switch v-model="isRepeat" class="md-primary"></md-switch>
            </div>
          </md-card-content>
          <md-card-content v-show="dates.length">
            <md-card-header data-background-color="blue" class="card-header">
              <div class="card-header-info">
                <h4 class="title">Настройка наград по дням</h4>
              </div>
            </md-card-header>
            <CalendarRewards :calendar-data="dates" />
            <div class="buttons-wrap justify-end">
              <md-button class="md-raised md-success"> Создать </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import CalendarRewards from "@/components/Tables/CalendarRewards.vue";
export default {
  components: {
    DatePicker,
    CalendarRewards,
  },
  data() {
    return {
      title: "",
      dates: [],
      dateTill: null,
      dateFrom: null,
      isRepeat: false,
    };
  },
  watch: {
    dateTill() {
      this.checkDate();
    },
    dateFrom() {
      this.checkDate();
    },
  },
  methods: {
    chandeDates() {
      this.dates = [];
      if (this.dateTill && this.dateFrom) {
        const dateTill = moment(this.dateTill, "DD.MM.YYYY");
        const dateFrom = moment(this.dateFrom, "DD.MM.YYYY");
        for (let i = dateTill; i.isBefore(dateFrom); i.add(1, "day")) {
          this.dates.push({
            date: i.format("DD.MM.YYYY"),
            item: null,
            numbers: 1,
            isSpecDay: false,
          });
        }
        this.dates.push({
          date: dateFrom.format("DD.MM.YYYY"),
          item: null,
          numbers: 1,
          isSpecDay: false,
        });
      }
    },
    checkDate() {
      if (this.dateTill && this.dateFrom) {
        const dateFrom = moment(this.dateFrom, "DD.MM.YYYY");
        const dateTill = moment(this.dateTill, "DD.MM.YYYY");
        if (dateFrom.isBefore(dateTill)) {
          this.dateFrom = this.dateTill;
          this.dateTill = dateFrom.format("DD.MM.YYYY");
        }
      }
      this.chandeDates();
    },
  },
};
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.xmx-datepicker,
.w-full {
  width: 100%;
}
.mr-10 {
  margin-right: 10px;
}
</style>
