var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('ValidationObserver',{ref:"addEmoji"},[_c('md-card-content',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Настройка основной информации")])])]),_c('ValidationProvider',{staticClass:"md-layout-item",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]}}])}),_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{staticClass:"md-layout-item",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("С")]),_c('DatePicker',{attrs:{"prefix-class":"xmx","type":"date","show-second":false,"lang":"ru","popup-style":{
                    left: 0,
                  },"append-to-body":false,"value-type":"format","format":"DD.MM.YYYY"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('md-input',{staticClass:"w-full",model:{value:(_vm.dateTill),callback:function ($$v) {_vm.dateTill=$$v},expression:"dateTill"}})]},proxy:true}],null,true),model:{value:(_vm.dateTill),callback:function ($$v) {_vm.dateTill=$$v},expression:"dateTill"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("По")]),_c('DatePicker',{attrs:{"prefix-class":"xmx","type":"date","show-second":false,"lang":"ru","popup-style":{
                    left: 0,
                  },"append-to-body":false,"value-type":"format","format":"DD.MM.YYYY"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('md-input',{staticClass:"w-full",model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})]},proxy:true}],null,true),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item d-flex align-items-center",attrs:{"mode":"passive","tag":"div"}},[_c('label',{staticClass:"mr-10"},[_vm._v("Повторяемость")]),_c('md-switch',{staticClass:"md-primary",model:{value:(_vm.isRepeat),callback:function ($$v) {_vm.isRepeat=$$v},expression:"isRepeat"}})],1)],1),_c('md-card-content',{directives:[{name:"show",rawName:"v-show",value:(_vm.dates.length),expression:"dates.length"}]},[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Настройка наград по дням")])])]),_c('CalendarRewards',{attrs:{"calendar-data":_vm.dates}}),_c('div',{staticClass:"buttons-wrap justify-end"},[_c('md-button',{staticClass:"md-raised md-success"},[_vm._v(" Создать ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }